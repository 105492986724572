.Title {
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  height: 5vh;
  margin: 2vh;
  font-family: MyFont;
}

hr {
  width: 50%
}
