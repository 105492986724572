.Key {
  height: 7.5vh;
  width: 4vh;
  max-width: 8vw;
  color: white;
  background-color: #818384;
  margin: 0.2vh;
  text-align: center;
  justify-content: center;
  line-height: 7.5vh;
  border-radius: 5px;
  user-select: none;
}

.Incorrect {
  height: 7.5vh;
  width: 4vh;
  max-width: 8vw;
  color: white;
  background-color: #3a3a3c;
  margin: 0.2vh;
  text-align: center;
  justify-content: center;
  line-height: 7.5vh;
  border-radius: 5px;
  user-select: none;
}

.Special {
  height: 7.5vh;
  width: auto;
  max-width: 15vw;
  color: white;
  background-color: #818384;
  margin: 0.2vh;
  text-align: center;
  justify-content: center;
  line-height: 7.5vh;
  border-radius: 5px;
  user-select: none;
}
